.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: #f0f0f000;
  padding: 15vw;
  color: #0f4c3a;
  font-size: 0.8em;
}

.h1 {
  font-size: 4em;
  margin-bottom: 0.5em;
  // font-weight: 600;
}
